.nav-link {
    color: white;
  }
  
  .logo {
    width: 230px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  
  .navbar {
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0px;
  }
  
  .phantom-navbar {
    height: 80px;
  }
  
  .main-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-right: 20px;
    padding-left: 20px;
    max-width: 1100px;
    margin: auto;
  }
  
  .navigation-link {
    color: white;
    text-decoration: none;
    font-family: 'Oswald', serif;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .navigation-link:hover {
    text-decoration: none;
    color: black;
  }
  
  .left-menu {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .right-menu {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .grid-item-one {
    width: 80%;
    justify-self: start;
    align-self: end;
  }
  
  .grid-item-two {
    justify-self: center;
  }
  
  .grid-item-three {
    width: 80%;
    justify-self: end;
    align-self: end;
  }
  
  .hamburger {
    display: none;
    color: white;
  }
  
  @media only screen and (max-width: 1050px) {
    .hamburger {
      display: block;
      float: right;
      padding-bottom: 12px;
      font-size: 28px;
      cursor: pointer;
    }
    .right-menu {
      display: none;
    }
    .left-menu {
      display: none;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .dropdown-content {
      right: 20px;
    }
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    cursor: pointer;
  }
  
  .dropdown-content-link {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content-link a {
    color: black;
  }
  
  .dropdown-content-link:hover {
    color: gray;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:active .dropdown-content {
    display: block;
  }
  