.hero {
    min-height: 600px;
    background-image: url('../common/images/showcaseone.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .form-text {
    color: #ff794d;
    text-align: center;
    padding-top: 30px;
  }
  
  .gray-container {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 6em;
  }
  
  .edit-content {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 15em;
  }
  
  .mycamps {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .my-camp-card-container {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .my-camp-card-image {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .my-camp-card-image:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .my-camp-card-text {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  .form-text a {
    color: #ff794d;
  }
  
  .form-text a:hover {
    text-decoration: none;
  }
  
  .header {
    position: fixed;
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    z-index: 100;
  
  }
  
  .form-container {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .subtext {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    color: #ff794d;
  }
  
  .subtext a {
    color: #ff794d;
    text-decoration: none;
  }
  
  .add-camp-form {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'orgName orgName orgName . . .'
      'campTitle campTitle campTitle . . .'
      'category category category category . .'
      'address address address address address address'
      'city city city state zip .'
      'phone phone campemail campemail . .'
      'startenddate startenddate . yearround . .'
      'agegroup spotsavailable costpercamper . . .'
      'campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours'
      'campdetails campdetails campdetails campdetails . .'
      '. . . . . addcampbutton'
      '. . . . responsemessage responsemessage';
    grid-gap: 10px;
  }

  
  
  .orgName {
    grid-area: orgName;
  }
  
  .campTitle {
    grid-area: campTitle;
  }
  
  .category {
    grid-area: category;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
  .imageupload {
    grid-area: imageupload;
    align-self: end;
  }
  
  .address {
    grid-area: address;
  }
  
  .city {
    grid-area: city;
  }
  
  .state {
    grid-area: state;
    align-self: end;
  }
  
  .dropdown {
    width: 100%;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 16px;
    height: 45px;
  }
  
  .zip {
    grid-area: zip;
  }
  
  .phone {
    grid-area: phone;
  }
  
  .campemail {
    grid-area: campemail;
  }
  
  .startenddate {
    grid-area: startenddate;
  }
  
  .yearround {
    grid-area: yearround;
  }
  
  /* .campdaysandhours {
    grid-area: campdaysandhours;
    width: 60%;
    max-width: 1100px;
  } */
  
  .agegroup {
    grid-area: agegroup;
    align-self: start;
  }
  
  .spotsavailable {
    grid-area: spotsavailable;
  }
  
  .costpercamper {
    grid-area: costpercamper;
  }
  
  .campdetails {
    grid-area: campdetails;
  }
  
  .addcampbutton {
    grid-area: addcampbutton;
  }
  
  .responsemessage {
    grid-area: responsemessage;
  }
  
  .agegroupinput {
    width: 50px;
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .campdayinput {
    margin: 20px;
  }
  
  .camphoursinput {
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .campdaysandhoursinput {
    display: grid;
    grid-template-columns: 100px 80px 1fr 1fr;
  }
  
  .nocamps {
    text-align: center;
    padding: 50px;
  }
  
  .registered-camper-container {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .registered-camper-table {
    border: 3px solid white;
    width: 100%;
    font-size: 0.9em;
  }
  
  .registered-camper-table tr,
  td {
    border: 3px solid white;
  }
  
  @media (max-width: 480px) {
    .registered-camper-container {
      width: 90%;
    }
  }
  
  .camp-registrations-card-image {
    width: 200px;
    height: 150px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .category {
      grid-area: category;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    .registered-camper-container {
      width: 85%;
    }
  
    .gray-container {
      width: 100%;
      background-color: whitesmoke;
      margin-top: 16em;
    }
  }

  div.react-datepicker-wrapper{
    width:100%;
  }