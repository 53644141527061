.hero {
    min-height: 400px;
    background-image: url('../common/images/showcaseone.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .form-container {
    padding-top: 30px;
    width: 70%;
    max-width: 1000px;
    margin: auto;
  }
  
  .form-container a {
    font-size: 24px;
    color: #ff794d;
  }
  
  .forgot-password {
    padding-top: 20px;
  }
  
  .form-text {
    color: #ff794d;
    text-align: center;
  }
  
  /* PaswordReset */
  
  .password-reset-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .password-reset-button {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .arrow-strip {
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    height: 100px;
    width: 100%;
  }
  
  .arrow-middle {
    color: white;
    width: 300px;
    height: 50px;
    margin: auto;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .form-container {
      width: 85%;
    }
  }
  
  .my-camp-card-text {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }

  form.register-form label{
    margin-bottom:0px;
    margin-top:10px;
    border:2px solid black;
  }
  