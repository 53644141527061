.masthead {
    min-height: 500px;
    background-image: url('./images/hero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .landing-container {
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
  
  /*  */
  
  .features-icons {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #36454f;
  }
  
  .features-icons .features-icons-item {
    max-width: 20rem;
  }
  
  .features-icons .features-icons-item .features-icons-icon {
    height: 7rem;
  }
  
  .features-icons .features-icons-item .features-icons-icon i {
    font-size: 4.5rem;
  }
  
  .features-icons .features-icons-item:hover .features-icons-icon i {
    font-size: 5rem;
  }
  
  .icon {
    width: 130px;
    height: 165px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    overflow: hidden;
  }
  
  /*  */
  
  .showcase .showcase-text {
    padding: 6rem;
    text-align: center;
    color: #ff794d;
    height: 20rem;
    min-height: 20rem;
  }
  
  .showcase .showcase-img {
    min-height: 20rem;
    height: 20rem;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  @media (min-width: 768px) {
    .showcase .showcase-text {
      /* padding: 7rem; */
    }
  }
  
  /*  */
  
  .call-to-action {
    position: relative;
    background-color: #343a40;
    background: url('./images/summerkids.jpg') no-repeat center center;
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  
  .call-to-action .overlay {
    position: absolute;
    background-color: #212529;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
  
  .mobile-screen-cover {
    display: none;
  }
  
  .mobile-screen-cover-image {
    height: 400px;
    background-image: url('./images/kidpaint-newbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  @media (max-width: 900px) {
    .masthead {
      display: none;
    }
  
    .mobile-screen-cover {
      display: block;
    }
  }
  
  .camper-tablet {
    background-color: #fbba16;
    color: #f5f5f5;
  }
  
  .camp-host-tablet {
    background-color: #ff794d;
    color: #f5f5f5;
  }
  
  .hero {
    min-height: 600px;
    background-image: url('./images/showcaseone.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .header-text {
    color: #ff794d;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
  }
  
  .link-text {
    color: #ff794d;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    text-decoration: underline;
  }
  
  .link-text:hover {
    color: #ff794d;
    cursor: pointer;
  }
  
  .form-container {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
  }
  
  .hero-text {
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
  }
  
  .hero-button {
    border-radius: 10px;
    background-color: white;
    color: #36454f;
    text-transform: uppercase;
    padding: 2px 40px;
    font-size: 20px;
    margin-top: 20px;
  }
  
  .box-text {
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: #ff794d;
  }
  
  .box-sub-text {
    color: #36454f;
  }
  
  .signup-button {
    border-radius: 10px;
    background-color: #ff794d;
    color: white;
    text-transform: uppercase;
    padding: 2px 40px;
    font-size: 28px;
    font-family: 'Oswald', sans-serif;
    border-color: #ff794d;
    width: 200px;
  }

  .accordion__button{
    background-color: #9c512c;
    color: #FFF;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-weight: bold;
  }

  .accordion__panel{
    padding: 20px;
    animation: 0.35s ease-in 0s 1 normal none running fadein;
    padding-left:0;
    padding-top:0;
  }
  