@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@font-face {
  font-family: 'ChaletComprime';
  src: url(/static/media/chaletcomprime.429c8de5.otf), format('otf');
  /* font-style: 'CologneSixty'; */
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
a {
  font-family: 'Oswald', sans-serif;
}

p {
  font-family: 'Montserrat', sans-serif;
}

.overhead {
  background-color: rgb(236, 78, 43);
  border-radius: 0px 0px 75px 25px;
  color: white;
  opacity: 0.89;
  margin-left: 5% !important;
  width: 32% !important;
  height: 40% !important;
  padding: 30px !important;
  line-height: 1.1 !important;
}

.lower-right {
  margin-top: 30px !important;
  margin-right: 1em !important;
  color: white;
  width: 25% !important;
  top: 10em;
  line-height: 1.1 !important;
}

.input {
  width: 100%;
  padding: 2.5px 2.5px;
  border: 2px solid #b9bbb6;
  border-radius: 10px;
  background-color: #d4d4d4;
  box-shadow: #b9bbb6;
  font-size: 24px;
}

div.container input{
  margin-bottom:20px;
}

.header-text {
  color: #ff794d;
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
}

#dropdown-basic-button {
  background-color: #ff794d;
  border: 1px solid #b9bbb6;
}

.Navbar_nav-link__SPrSM {
    color: white;
  }
  
  .Navbar_logo__7VWO6 {
    width: 230px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  
  .Navbar_navbar__1lOET {
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0px;
  }
  
  .Navbar_phantom-navbar__26EZD {
    height: 80px;
  }
  
  .Navbar_main-nav__1xxCT {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-right: 20px;
    padding-left: 20px;
    max-width: 1100px;
    margin: auto;
  }
  
  .Navbar_navigation-link__2wPv4 {
    color: white;
    text-decoration: none;
    font-family: 'Oswald', serif;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .Navbar_navigation-link__2wPv4:hover {
    text-decoration: none;
    color: black;
  }
  
  .Navbar_left-menu__3Tc7I {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .Navbar_right-menu__11qB7 {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .Navbar_grid-item-one__413MC {
    width: 80%;
    justify-self: start;
    align-self: end;
  }
  
  .Navbar_grid-item-two__1stkh {
    justify-self: center;
  }
  
  .Navbar_grid-item-three__myABr {
    width: 80%;
    justify-self: end;
    align-self: end;
  }
  
  .Navbar_hamburger__1mRST {
    display: none;
    color: white;
  }
  
  @media only screen and (max-width: 1050px) {
    .Navbar_hamburger__1mRST {
      display: block;
      float: right;
      padding-bottom: 12px;
      font-size: 28px;
      cursor: pointer;
    }
    .Navbar_right-menu__11qB7 {
      display: none;
    }
    .Navbar_left-menu__3Tc7I {
      display: none;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .Navbar_dropdown-content__37AZ1 {
      right: 20px;
    }
  }
  
  .Navbar_dropdown-content__37AZ1 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    cursor: pointer;
  }
  
  .Navbar_dropdown-content-link__1iEV1 {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .Navbar_dropdown-content-link__1iEV1 a {
    color: black;
  }
  
  .Navbar_dropdown-content-link__1iEV1:hover {
    color: gray;
  }
  
  .Navbar_dropdown__18M-_:hover .Navbar_dropdown-content__37AZ1 {
    display: block;
  }
  
  .Navbar_dropdown__18M-_:active .Navbar_dropdown-content__37AZ1 {
    display: block;
  }
  
.Landing_masthead__rTMTw {
    min-height: 500px;
    background-image: url(/static/media/hero.591793fe.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .Landing_landing-container__37kb5 {
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
  
  /*  */
  
  .Landing_features-icons__v0p7e {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #36454f;
  }
  
  .Landing_features-icons__v0p7e .Landing_features-icons-item__3jgQS {
    max-width: 20rem;
  }
  
  .Landing_features-icons__v0p7e .Landing_features-icons-item__3jgQS .Landing_features-icons-icon__2lVao {
    height: 7rem;
  }
  
  .Landing_features-icons__v0p7e .Landing_features-icons-item__3jgQS .Landing_features-icons-icon__2lVao i {
    font-size: 4.5rem;
  }
  
  .Landing_features-icons__v0p7e .Landing_features-icons-item__3jgQS:hover .Landing_features-icons-icon__2lVao i {
    font-size: 5rem;
  }
  
  .Landing_icon__Gwx35 {
    width: 130px;
    height: 165px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    overflow: hidden;
  }
  
  /*  */
  
  .Landing_showcase__1v1p2 .Landing_showcase-text__26kl_ {
    padding: 6rem;
    text-align: center;
    color: #ff794d;
    height: 20rem;
    min-height: 20rem;
  }
  
  .Landing_showcase__1v1p2 .Landing_showcase-img__1fHEH {
    min-height: 20rem;
    height: 20rem;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  @media (min-width: 768px) {
    .Landing_showcase__1v1p2 .Landing_showcase-text__26kl_ {
      /* padding: 7rem; */
    }
  }
  
  /*  */
  
  .Landing_call-to-action__2avSl {
    position: relative;
    background-color: #343a40;
    background: url(/static/media/summerkids.ba269b3b.jpg) no-repeat center center;
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  
  .Landing_call-to-action__2avSl .Landing_overlay__tE4h9 {
    position: absolute;
    background-color: #212529;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
  
  .Landing_mobile-screen-cover__34j2y {
    display: none;
  }
  
  .Landing_mobile-screen-cover-image__cBbgb {
    height: 400px;
    background-image: url(/static/media/kidpaint-newbg.cd395b09.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  @media (max-width: 900px) {
    .Landing_masthead__rTMTw {
      display: none;
    }
  
    .Landing_mobile-screen-cover__34j2y {
      display: block;
    }
  }
  
  .Landing_camper-tablet__5ZQSf {
    background-color: #fbba16;
    color: #f5f5f5;
  }
  
  .Landing_camp-host-tablet__1jJVi {
    background-color: #ff794d;
    color: #f5f5f5;
  }
  
  .Landing_hero__2Yb-5 {
    min-height: 600px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .Landing_header-text__2FbBX {
    color: #ff794d;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
  }
  
  .Landing_link-text__2dkxq {
    color: #ff794d;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    text-decoration: underline;
  }
  
  .Landing_link-text__2dkxq:hover {
    color: #ff794d;
    cursor: pointer;
  }
  
  .Landing_form-container__uyuVI {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
  }
  
  .Landing_hero-text__Rr7n5 {
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
  }
  
  .Landing_hero-button__2jV7s {
    border-radius: 10px;
    background-color: white;
    color: #36454f;
    text-transform: uppercase;
    padding: 2px 40px;
    font-size: 20px;
    margin-top: 20px;
  }
  
  .Landing_box-text__12pvL {
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: #ff794d;
  }
  
  .Landing_box-sub-text__2PXQa {
    color: #36454f;
  }
  
  .Landing_signup-button__zzKsE {
    border-radius: 10px;
    background-color: #ff794d;
    color: white;
    text-transform: uppercase;
    padding: 2px 40px;
    font-size: 28px;
    font-family: 'Oswald', sans-serif;
    border-color: #ff794d;
    width: 200px;
  }

  .Landing_accordion__button__2y9lI{
    background-color: #9c512c;
    color: #FFF;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-weight: bold;
  }

  .Landing_accordion__panel__2C0ai{
    padding: 20px;
    -webkit-animation: 0.35s ease-in 0s 1 normal none running :local(fadein);
            animation: 0.35s ease-in 0s 1 normal none running :local(fadein);
    padding-left:0;
    padding-top:0;
  }
  
.Footer_footer-container__3dVri {
    padding-top: 30px;
    display: grid;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    font-size: 24px;
    background: white;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'grid-item-one grid-item-two grid-item-three'
      'grid-item-four grid-item-five grid-item-six';
  }
  
  .Footer_footer-container__3dVri a:hover {
    text-decoration: none;
    color: black;
  }
  
  .Footer_grid-item-two__Td3n7 {
    display: flex;
    justify-self: center;
    align-self: end;
    grid-area: grid-item-two;
  }
  
  .Footer_grid-item-four__16OXP {
    grid-area: grid-item-four;
    align-self: center;
  }
  
  .Footer_grid-item-five__13o9i {
    grid-area: grid-item-five;
    justify-self: center;
  }
  
  .Footer_grid-item-six__2IWKK {
    grid-area: grid-item-six;
    align-self: center;
  }
  
  .Footer_logo__2UcT6 {
    width: 260px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  
  .Footer_navigation-link__20X5i {
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: #36454f;
  }
  
  .Footer_menu__1tBJM {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .Footer_social-icon__2Zib_ {
    height: 60px;
    width: 60px;
    padding: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .Footer_social-icon__2Zib_:hover {
    transform: translateY(-6px);
  }
  
  @media only screen and (max-width: 1000px) {
    .Footer_footer-container__3dVri {
      display: grid;
      width: 90%;
      margin: auto;
      font-size: 24px;
      background: white;
      grid-template-columns: 1fr;
      grid-template-rows: 85px 85px 25px 25px;
      grid-template-areas:
        'grid-item-two'
        'grid-item-five'
        'grid-item-four'
        'grid-item-six';
      align-items: center;
    }
    .Footer_grid-item-four__16OXP {
      display: flex;
      justify-content: center;
    }
    .Footer_grid-item-six__2IWKK {
      display: flex;
      justify-content: center;
    }
    .Footer_menu__1tBJM {
      padding: 0;
      justify-content: center;
    }
    .Footer_menu__1tBJM a {
      padding: 5px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .Footer_footer-container__3dVri {
      grid-template-areas:
        'grid-item-two'
        'grid-item-five'
        'grid-item-six';
    }
    .Footer_grid-item-four__16OXP {
      display: none;
    }
  }
  
.Button_btn--default__14oJ0 {
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    color: white;
    min-width: 180px;
    padding: 2.5px;
    text-transform: uppercase;
    border: none;
    transition: transform 0.3s ease;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
  }
  
  .Button_btn--large___N0LB {
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    color: white;
    min-width: 220px;
    padding: 2.5px;
    text-transform: uppercase;
    border: none;
    transition: transform 0.3s ease;
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
  }
  
  .Button_btn--default__14oJ0:hover,
  .Button_btn--large___N0LB:hover {
    transform: translateY(-3px);
    color: white;
  }
  
  .Button_icon__SxBc2 {
    width: 20px;
  }
  
.Auth_hero__1_XzK {
    min-height: 400px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .Auth_form-container__22MiP {
    padding-top: 30px;
    width: 70%;
    max-width: 1000px;
    margin: auto;
  }
  
  .Auth_form-container__22MiP a {
    font-size: 24px;
    color: #ff794d;
  }
  
  .Auth_forgot-password___5eg9 {
    padding-top: 20px;
  }
  
  .Auth_form-text__pDQdZ {
    color: #ff794d;
    text-align: center;
  }
  
  /* PaswordReset */
  
  .Auth_password-reset-form-container__340Me {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Auth_password-reset-button__2KdUX {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .Auth_arrow-strip__gX65S {
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    height: 100px;
    width: 100%;
  }
  
  .Auth_arrow-middle__3KMzn {
    color: white;
    width: 300px;
    height: 50px;
    margin: auto;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .Auth_form-container__22MiP {
      width: 85%;
    }
  }
  
  .Auth_my-camp-card-text__ThxN9 {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }

  form.Auth_register-form__2iTTj label{
    margin-bottom:0px;
    margin-top:10px;
    border:2px solid black;
  }
  
.Auth_hero__1zTWI {
    min-height: 400px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .Auth_form-container__33xTS {
    padding-top: 30px;
    width: 70%;
    max-width: 1000px;
    margin: auto;
  }
  
  .Auth_form-container__33xTS a {
    font-size: 24px;
    color: #ff794d;
  }
  
  .Auth_forgot-password__34xXY {
    padding-top: 20px;
  }
  
  .Auth_form-text__1w49p {
    color: #ff794d;
    text-align: center;
  }
  
  /* PaswordReset */
  
  .Auth_password-reset-form-container__1ScnB {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Auth_password-reset-button__1g4Yc {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .Auth_arrow-strip__3-a5v {
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    height: 100px;
    width: 100%;
  }
  
  .Auth_arrow-middle__1Va4p {
    color: white;
    width: 300px;
    height: 50px;
    margin: auto;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .Auth_form-container__33xTS {
      width: 85%;
    }
  }
  
  .Auth_my-camp-card-text__jqW15 {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }

  form.Auth_register-form__1VUhJ label{
    margin-bottom:0px;
    margin-top:10px;
    border:2px solid black;
  }
  
.CampHost_hero__CrLdM {
    min-height: 600px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .CampHost_form-text__27YoG {
    color: #ff794d;
    text-align: center;
    padding-top: 30px;
  }
  
  .CampHost_gray-container__Bz8tU {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 6em;
  }
  
  .CampHost_edit-content__30GWD {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 15em;
  }
  
  .CampHost_mycamps__8t6-C {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .CampHost_my-camp-card-container__3PW2Z {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .CampHost_my-camp-card-image__OEtlK {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .CampHost_my-camp-card-image__OEtlK:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .CampHost_my-camp-card-text__3Jpjo {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  .CampHost_form-text__27YoG a {
    color: #ff794d;
  }
  
  .CampHost_form-text__27YoG a:hover {
    text-decoration: none;
  }
  
  .CampHost_header__3zUYX {
    position: fixed;
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    z-index: 100;
  
  }
  
  .CampHost_form-container__pMhc- {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .CampHost_subtext__313hU {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    color: #ff794d;
  }
  
  .CampHost_subtext__313hU a {
    color: #ff794d;
    text-decoration: none;
  }
  
  .CampHost_add-camp-form__dqrU5 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'orgName orgName orgName . . .'
      'campTitle campTitle campTitle . . .'
      'category category category category . .'
      'address address address address address address'
      'city city city state zip .'
      'phone phone campemail campemail . .'
      'startenddate startenddate . yearround . .'
      'agegroup spotsavailable costpercamper . . .'
      'campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours'
      'campdetails campdetails campdetails campdetails . .'
      '. . . . . addcampbutton'
      '. . . . responsemessage responsemessage';
    grid-gap: 10px;
  }

  
  
  .CampHost_orgName__1IA-Z {
    grid-area: orgName;
  }
  
  .CampHost_campTitle__1J_2g {
    grid-area: campTitle;
  }
  
  .CampHost_category__1zLhG {
    grid-area: category;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
  .CampHost_imageupload__2Bryk {
    grid-area: imageupload;
    align-self: end;
  }
  
  .CampHost_address__3Tj_3 {
    grid-area: address;
  }
  
  .CampHost_city__3fw3K {
    grid-area: city;
  }
  
  .CampHost_state__3xIxD {
    grid-area: state;
    align-self: end;
  }
  
  .CampHost_dropdown__3w08P {
    width: 100%;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 16px;
    height: 45px;
  }
  
  .CampHost_zip__3PUH6 {
    grid-area: zip;
  }
  
  .CampHost_phone__NzxwB {
    grid-area: phone;
  }
  
  .CampHost_campemail__RyJai {
    grid-area: campemail;
  }
  
  .CampHost_startenddate__1_TeL {
    grid-area: startenddate;
  }
  
  .CampHost_yearround__3rckF {
    grid-area: yearround;
  }
  
  /* .campdaysandhours {
    grid-area: campdaysandhours;
    width: 60%;
    max-width: 1100px;
  } */
  
  .CampHost_agegroup__2e68E {
    grid-area: agegroup;
    align-self: start;
  }
  
  .CampHost_spotsavailable__3ybXu {
    grid-area: spotsavailable;
  }
  
  .CampHost_costpercamper__1R6QJ {
    grid-area: costpercamper;
  }
  
  .CampHost_campdetails__3mWED {
    grid-area: campdetails;
  }
  
  .CampHost_addcampbutton__1v2X6 {
    grid-area: addcampbutton;
  }
  
  .CampHost_responsemessage__6FDx2 {
    grid-area: responsemessage;
  }
  
  .CampHost_agegroupinput__3wmhu {
    width: 50px;
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdayinput__32TR8 {
    margin: 20px;
  }
  
  .CampHost_camphoursinput__1lE3L {
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdaysandhoursinput__199a2 {
    display: grid;
    grid-template-columns: 100px 80px 1fr 1fr;
  }
  
  .CampHost_nocamps__1JMzU {
    text-align: center;
    padding: 50px;
  }
  
  .CampHost_registered-camper-container__1yUM0 {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .CampHost_registered-camper-table__X-yYM {
    border: 3px solid white;
    width: 100%;
    font-size: 0.9em;
  }
  
  .CampHost_registered-camper-table__X-yYM tr,
  td {
    border: 3px solid white;
  }
  
  @media (max-width: 480px) {
    .CampHost_registered-camper-container__1yUM0 {
      width: 90%;
    }
  }
  
  .CampHost_camp-registrations-card-image__1bG7M {
    width: 200px;
    height: 150px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .CampHost_category__1zLhG {
      grid-area: category;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    .CampHost_registered-camper-container__1yUM0 {
      width: 85%;
    }
  
    .CampHost_gray-container__Bz8tU {
      width: 100%;
      background-color: whitesmoke;
      margin-top: 16em;
    }
  }

  div.CampHost_react-datepicker-wrapper__3T-YF{
    width:100%;
  }
.success{text-align:center}.success h2{color:seagreen}.success a{display:block}.checkout-form p{text-align:center}.checkout-form label{min-width:500px;color:#6b7c93;font-weight:300;letter-spacing:0.025em}.checkout-form input,.checkout-form .StripeElement{display:block;margin:10px 0 20px 0;padding:10px 14px;font-size:30px;border:0;outline:0;border-radius:4px;background:#d4d4d4}.checkout-form input::-webkit-input-placeholder, .checkout-form .StripeElement::-webkit-input-placeholder{color:#aab7c4}.checkout-form input:-ms-input-placeholder, .checkout-form .StripeElement:-ms-input-placeholder{color:#aab7c4}.checkout-form input::-ms-input-placeholder, .checkout-form .StripeElement::-ms-input-placeholder{color:#aab7c4}.checkout-form input::placeholder,.checkout-form .StripeElement::placeholder{color:#aab7c4}.checkout-form input:focus,.checkout-form input .StripeElement--focus,.checkout-form .StripeElement:focus,.checkout-form .StripeElement .StripeElement--focus{box-shadow:rgba(50,50,93,0.109804) 0px 4px 6px,rgba(0,0,0,0.0784314) 0px 1px 3px;transition:all 150ms ease}.checkout-form .order-button{display:flex;align-items:center;justify-content:center;margin:0 auto;text-decoration:none;margin-top:5%;background-color:#008cdd;border:1px solid #008cdd;font-size:1em;font-weight:bold;color:white;border-radius:5px;padding:0.5em 1em;width:200px;transition:opacity 200ms;cursor:pointer}.checkout-form .order-button.success{background-color:seagreen;border-color:seagreen}.checkout-form .order-button:hover{opacity:0.8}.checkout-form .order-button:disabled{opacity:0.5;cursor:not-allowed}.checkout-form a{display:block;text-align:center;margin-top:1em}

.Camp_hero__35kdV {
    min-height: 600px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .Camp_form-text__3cwPs {
    color: #ff794d;
    text-align: center;
    padding-top: 30px;
  }
  
  .Camp_gray-container___0-we {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 6em;
  }
  
  .Camp_edit-content__3ST6P {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 15em;
  }
  
  .Camp_mycamps__dEvYG {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .Camp_my-camp-card-container__1HFOv {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .Camp_my-camp-card-image__A0tIU {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .Camp_my-camp-card-image__A0tIU:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .Camp_my-camp-card-text__3egDz {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  .Camp_form-text__3cwPs a {
    color: #ff794d;
  }
  
  .Camp_form-text__3cwPs a:hover {
    text-decoration: none;
  }
  
  .Camp_header__1d2vO {
    position: fixed;
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    z-index: 100;
  
  }
  
  .Camp_form-container__3ITFv {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .Camp_subtext__2Cfxw {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    color: #ff794d;
  }
  
  .Camp_subtext__2Cfxw a {
    color: #ff794d;
    text-decoration: none;
  }
  
  .Camp_add-camp-form__2bI-f {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'orgName orgName orgName . . .'
      'campTitle campTitle campTitle . . .'
      'category category category category . .'
      'address address address address address address'
      'city city city state zip .'
      'phone phone campemail campemail . .'
      'startenddate startenddate . yearround . .'
      'agegroup spotsavailable costpercamper . . .'
      'campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours'
      'campdetails campdetails campdetails campdetails . .'
      '. . . . . addcampbutton'
      '. . . . responsemessage responsemessage';
    grid-gap: 10px;
  }

  
  
  .Camp_orgName__3U4jF {
    grid-area: orgName;
  }
  
  .Camp_campTitle__15EXD {
    grid-area: campTitle;
  }
  
  .Camp_category__3Edxw {
    grid-area: category;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
  .Camp_imageupload__1Z0KI {
    grid-area: imageupload;
    align-self: end;
  }
  
  .Camp_address__2D3vJ {
    grid-area: address;
  }
  
  .Camp_city__13hZv {
    grid-area: city;
  }
  
  .Camp_state__2IV4G {
    grid-area: state;
    align-self: end;
  }
  
  .Camp_dropdown__1Bnmu {
    width: 100%;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 16px;
    height: 45px;
  }
  
  .Camp_zip__2BABo {
    grid-area: zip;
  }
  
  .Camp_phone__27Svn {
    grid-area: phone;
  }
  
  .Camp_campemail__2ZIed {
    grid-area: campemail;
  }
  
  .Camp_startenddate__1cjgE {
    grid-area: startenddate;
  }
  
  .Camp_yearround__2BEyH {
    grid-area: yearround;
  }
  
  /* .campdaysandhours {
    grid-area: campdaysandhours;
    width: 60%;
    max-width: 1100px;
  } */
  
  .Camp_agegroup__1RM-H {
    grid-area: agegroup;
    align-self: start;
  }
  
  .Camp_spotsavailable__1h4hm {
    grid-area: spotsavailable;
  }
  
  .Camp_costpercamper__12v2l {
    grid-area: costpercamper;
  }
  
  .Camp_campdetails__3t3w_ {
    grid-area: campdetails;
  }
  
  .Camp_addcampbutton__1mMwU {
    grid-area: addcampbutton;
  }
  
  .Camp_responsemessage__3s_IF {
    grid-area: responsemessage;
  }
  
  .Camp_agegroupinput__3Ws_S {
    width: 50px;
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .Camp_campdayinput__1bHdp {
    margin: 20px;
  }
  
  .Camp_camphoursinput__j64fy {
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .Camp_campdaysandhoursinput__2RbDr {
    display: grid;
    grid-template-columns: 100px 80px 1fr 1fr;
  }
  
  .Camp_nocamps__2G9qo {
    text-align: center;
    padding: 50px;
  }
  
  .Camp_registered-camper-container__1FjYe {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .Camp_registered-camper-table__-vVjC {
    border: 3px solid white;
    width: 100%;
    font-size: 0.9em;
  }
  
  .Camp_registered-camper-table__-vVjC tr,
  td {
    border: 3px solid white;
  }
  
  @media (max-width: 480px) {
    .Camp_registered-camper-container__1FjYe {
      width: 90%;
    }
  }
  
  .Camp_camp-registrations-card-image__3bli3 {
    width: 200px;
    height: 150px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .Camp_category__3Edxw {
      grid-area: category;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    .Camp_registered-camper-container__1FjYe {
      width: 85%;
    }
  
    .Camp_gray-container___0-we {
      width: 100%;
      background-color: whitesmoke;
      margin-top: 16em;
    }
  }

  div.Camp_react-datepicker-wrapper__3eBbI{
    width:100%;
  }
.CampHost_hero__1sZ9U {
    min-height: 600px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .CampHost_form-text__gdO-l {
    color: #ff794d;
    text-align: center;
    padding-top: 30px;
  }
  
  .CampHost_gray-container__1goKN {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 6em;
  }
  
  .CampHost_edit-content__3Y7ez {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 15em;
  }
  
  .CampHost_mycamps__3PaXF {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .CampHost_my-camp-card-container__UuvD3 {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .CampHost_my-camp-card-image__1gG5B {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .CampHost_my-camp-card-image__1gG5B:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .CampHost_my-camp-card-text__2hoIn {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  .CampHost_form-text__gdO-l a {
    color: #ff794d;
  }
  
  .CampHost_form-text__gdO-l a:hover {
    text-decoration: none;
  }
  
  .CampHost_header__xHLJY {
    position: fixed;
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    z-index: 100;
  
  }
  
  .CampHost_form-container__1aNb4 {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .CampHost_subtext__2rdXk {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    color: #ff794d;
  }
  
  .CampHost_subtext__2rdXk a {
    color: #ff794d;
    text-decoration: none;
  }
  
  .CampHost_add-camp-form__1zBp8 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'orgName orgName orgName . . .'
      'campTitle campTitle campTitle . . .'
      'category category category category . .'
      'address address address address address address'
      'city city city state zip .'
      'phone phone campemail campemail . .'
      'startenddate startenddate . yearround . .'
      'agegroup spotsavailable costpercamper . . .'
      'campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours'
      'campdetails campdetails campdetails campdetails . .'
      '. . . . . addcampbutton'
      '. . . . responsemessage responsemessage';
    grid-gap: 10px;
  }

  
  
  .CampHost_orgName__2ybn- {
    grid-area: orgName;
  }
  
  .CampHost_campTitle__nLelc {
    grid-area: campTitle;
  }
  
  .CampHost_category__-6YrN {
    grid-area: category;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
  .CampHost_imageupload__10mSP {
    grid-area: imageupload;
    align-self: end;
  }
  
  .CampHost_address__15IdT {
    grid-area: address;
  }
  
  .CampHost_city__2Lhnp {
    grid-area: city;
  }
  
  .CampHost_state__1X0S2 {
    grid-area: state;
    align-self: end;
  }
  
  .CampHost_dropdown__1WuTq {
    width: 100%;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 16px;
    height: 45px;
  }
  
  .CampHost_zip__2K1Vf {
    grid-area: zip;
  }
  
  .CampHost_phone__3aWDt {
    grid-area: phone;
  }
  
  .CampHost_campemail__1FY9a {
    grid-area: campemail;
  }
  
  .CampHost_startenddate__3UKTj {
    grid-area: startenddate;
  }
  
  .CampHost_yearround__1OxFu {
    grid-area: yearround;
  }
  
  /* .campdaysandhours {
    grid-area: campdaysandhours;
    width: 60%;
    max-width: 1100px;
  } */
  
  .CampHost_agegroup__3DkQ1 {
    grid-area: agegroup;
    align-self: start;
  }
  
  .CampHost_spotsavailable__2vd0z {
    grid-area: spotsavailable;
  }
  
  .CampHost_costpercamper__26ZHj {
    grid-area: costpercamper;
  }
  
  .CampHost_campdetails__2yN5p {
    grid-area: campdetails;
  }
  
  .CampHost_addcampbutton__2lrOt {
    grid-area: addcampbutton;
  }
  
  .CampHost_responsemessage__30pqF {
    grid-area: responsemessage;
  }
  
  .CampHost_agegroupinput__1zlJ5 {
    width: 50px;
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdayinput__1Uw5C {
    margin: 20px;
  }
  
  .CampHost_camphoursinput__1Wszi {
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdaysandhoursinput__u855_ {
    display: grid;
    grid-template-columns: 100px 80px 1fr 1fr;
  }
  
  .CampHost_nocamps__3vnZp {
    text-align: center;
    padding: 50px;
  }
  
  .CampHost_registered-camper-container__1nQjC {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .CampHost_registered-camper-table__1SgtJ {
    border: 3px solid white;
    width: 100%;
    font-size: 0.9em;
  }
  
  .CampHost_registered-camper-table__1SgtJ tr,
  td {
    border: 3px solid white;
  }
  
  @media (max-width: 480px) {
    .CampHost_registered-camper-container__1nQjC {
      width: 90%;
    }
  }
  
  .CampHost_camp-registrations-card-image__3xGOj {
    width: 200px;
    height: 150px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .CampHost_category__-6YrN {
      grid-area: category;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    .CampHost_registered-camper-container__1nQjC {
      width: 85%;
    }
  
    .CampHost_gray-container__1goKN {
      width: 100%;
      background-color: whitesmoke;
      margin-top: 16em;
    }
  }

  div.CampHost_react-datepicker-wrapper____FP7{
    width:100%;
  }
.Search_hero__35_S6 {
    min-height: 400px;
    background-image: url(/static/media/campsearchbanner.b8ef8fae.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    height: 400px;
    margin: auto;
  }

  .Search_category-list__2qYrl{
    list-style-type:"none";
    width:950;
    margin-top:15;
  }
  .Search_category-list__2qYrl li{
    float:left;
  }
  @media (max-width: 480px) {
    li{
      margin-right:0 !important;
    }
    li:nth-child(even){
      margin-left:.5rem
    } 
  }


  .Search_header__1vdBQ {
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    color: #ff794d;
    margin-top:10px;
  }
  
  .Search_hero-text__BENJT {
    width: 300px;
    height: 150px;
    text-align: center;
    font-size: 35px;
    line-height: 5%;
    padding-top: 20px;
    color: white;
  }
  
  .Search_hero-text-emphasis__3_Za_ {
    font-size: 70px;
    line-height: normal;
  }
  
  .Search_search__13Bbg {
    width: 70%;
    max-width: 1100px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .Search_camp-search-form__1Yu7A {
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas: 'grid-item-one grid-item-two grid-item-three';
  }
  
  .Search_grid-item-one__1MiN0 {
    grid-area: grid-item-one;
  }
  
  .Search_grid-item-two__211wL {
    grid-area: grid-item-two;
  }
  
  .Search_grid-item-three__3ex64 {
    grid-area: grid-item-three;
  }
  
  .Search_header-text__3fDgb {
    text-align: center;
    padding-top: 50px;
    color: #ff794d;
  }
  
  b {
    text-align: center;
    color: #ff794d;
  }
  
  .Search_gray-container__21CB- {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 10px;
  }
  
  .Search_camp-category-card-container__3goWG {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .Search_camp-category-card-image__XEjdA {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .Search_camp-category-card-image__XEjdA:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .Search_camp-category-card-text__3PQww {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  /* CampList and CampCard */
  
  .Search_camps__bLDSM {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
  }
  
  .Search_camp-card-container__1CDlQ {
    width: 750px;
    height: 325px;
    margin: 15px;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'image campinfo'
      '. button';
    padding: 20px;
  }
  
  .Search_camp-card-image__2tDGX {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    margin: 15px;
    grid-area: image;
  }
  
  .Search_camp-card-info__228qq {
    width: 450px;
    height: 250px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr 1fr 2fr;
    grid-template-areas:
      'name campname'
      'location camplocation'
      'dates campdates'
      'summary campsummary';
    grid-area: campinfo;
    margin: 15px;
  }
  
  .Search_camp-card-button__1B5fU {
    grid-area: button;
    margin: auto;
    justify-self: center;
  }
  
  .Search_name__2tQ0X {
    grid-area: name;
  }
  
  .Search_campname__2884W {
    grid-area: campname;
  }
  
  .Search_location__3SZg9 {
    grid-area: location;
  }
  
  .Search_camplocation__1dV73 {
    grid-area: camplocation;
  }
  
  .Search_dates__1oqCI {
    grid-area: dates;
  }
  
  .Search_campdates__miEqc {
    grid-area: campdates;
  }
  
  .Search_summary__3FRPJ {
    grid-area: summary;
  }
  
  .Search_campsummary__14nWh {
    grid-area: campsummary;
  }
  
  .Search_camp-card-header-text__2WCfw {
    font-family: 'Oswald', sans-serif;
  }
  
  /* CampProfile */
  
  .Search_camp-profile-primary-info-container__3Nmz0 {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'carousel primaryinfo';
    padding-bottom: 35px;
  }
  
  .Search_camp-profile-carousel__3ncEP {
    grid-area: carousel;
  }
  
  .Search_camp-profile-primary-info__1eSlw {
    grid-area: primaryinfo;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
      'campname campnameinfo'
      'camphost camphostinfo'
      'address addressinfo'
      'dates datesinfo'
      'age ageinfo'
      /*'categories categoriesinfo'*/
      'contact contactinfo'
      'cost costinfo'
      'spots spotsinfo'
      'button button';
    padding: 20px;
    grid-gap: 5px;
  }

  .Search_org-profile-primary-info__2Pkle{
    grid-area: primaryinfo;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
      'orgname orgnameinfo'
      'address addressinfo'
      'contact contactinfo'
      'button button';
    padding: 20px;
    grid-gap: 5px;
  }
  
  .Search_camp-profile-header-text__3BdAG {
    font-family: 'Oswald', sans-serif;
  }
  
  .Search_camp-profile-additional-info__1zXE5 {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    padding-bottom: 35px;
  }
  
  .Search_campname__2884W {
    grid-area: campname;
  }
  
  .Search_campnameinfo__23SbZ {
    grid-area: campnameinfo;
  }
  
  .Search_camphost__3GVte {
    grid-area: camphost;
  }
  
  .Search_camphostinfo__1Bat5 {
    grid-area: camphostinfo;
  }
  
  .Search_website__3pmyf {
    grid-area: website;
  }
  
  .Search_websiteinfo__1sT7s {
    grid-area: websiteinfo;
  }
  
  .Search_address__kXTdM {
    grid-area: address;
  }
  
  .Search_addressinfo__35wKi {
    grid-area: addressinfo;
  }
  
  .Search_dates__1oqCI {
    grid-area: dates;
  }
  
  .Search_datesinfo__10_Kt {
    grid-area: datesinfo;
  }
  
  .Search_time__1FQus {
    grid-area: time;
  }
  
  .Search_timeinfo__1YXQB {
    grid-area: timeinfo;
  }
  
  .Search_age__tdaie {
    grid-area: age;
  }
  
  .Search_ageinfo__cde7C {
    grid-area: ageinfo;
  }
  
  .Search_button__mBLuw {
    grid-area: button;
  }

  .Search_hero-button__1HPl5 {
    border-radius: 10px;
    background-color: white;
    color: #36454f;
    text-transform: uppercase;
    padding: 2px 40px;
    font-size: 20px;
    margin-top: 20px;
  }


.CampHost_hero__2_5n0 {
    min-height: 600px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .CampHost_form-text__Tf_tb {
    color: #ff794d;
    text-align: center;
    padding-top: 30px;
  }
  
  .CampHost_gray-container__J5qen {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 6em;
  }
  
  .CampHost_edit-content__2ENIz {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 15em;
  }
  
  .CampHost_mycamps__3wMT7 {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .CampHost_my-camp-card-container__3lbe0 {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .CampHost_my-camp-card-image__w5-Kp {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .CampHost_my-camp-card-image__w5-Kp:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .CampHost_my-camp-card-text__17Bx2 {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  .CampHost_form-text__Tf_tb a {
    color: #ff794d;
  }
  
  .CampHost_form-text__Tf_tb a:hover {
    text-decoration: none;
  }
  
  .CampHost_header__1j5Dm {
    position: fixed;
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    z-index: 100;
  
  }
  
  .CampHost_form-container__2_NF9 {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .CampHost_subtext__3yUrN {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    color: #ff794d;
  }
  
  .CampHost_subtext__3yUrN a {
    color: #ff794d;
    text-decoration: none;
  }
  
  .CampHost_add-camp-form__CX9BO {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'orgName orgName orgName . . .'
      'campTitle campTitle campTitle . . .'
      'category category category category . .'
      'address address address address address address'
      'city city city state zip .'
      'phone phone campemail campemail . .'
      'startenddate startenddate . yearround . .'
      'agegroup spotsavailable costpercamper . . .'
      'campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours'
      'campdetails campdetails campdetails campdetails . .'
      '. . . . . addcampbutton'
      '. . . . responsemessage responsemessage';
    grid-gap: 10px;
  }

  .CampHost_camp-profile-primary-info__1EJKB {
    grid-area: primaryinfo;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
      'campname campnameinfo'
      'camphost camphostinfo'
      'address addressinfo'
      'dates datesinfo'
      'age ageinfo'
      /*'categories categoriesinfo'*/
      'contact contactinfo'
      'cost costinfo'
      'spots spotsinfo'
      'button button';
    padding: 20px;
    grid-gap: 5px;
  }
  
  .CampHost_orgName__IKaPD {
    grid-area: orgName;
  }
  
  .CampHost_campTitle__2chrt {
    grid-area: campTitle;
  }
  
  .CampHost_category__1Enqi {
    grid-area: category;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
  .CampHost_imageupload__2H7-d {
    grid-area: imageupload;
    align-self: end;
  }
  
  .CampHost_address__3LrWN {
    grid-area: address;
  }
  
  .CampHost_city__3OU7Q {
    grid-area: city;
  }
  
  .CampHost_state__3cQrq {
    grid-area: state;
    align-self: end;
  }
  
  .CampHost_dropdown__1pYgc {
    width: 100%;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 16px;
    height: 45px;
  }
  
  .CampHost_zip__lhzOz {
    grid-area: zip;
  }
  
  .CampHost_phone__2UsjL {
    grid-area: phone;
  }
  
  .CampHost_campemail__3Nklo {
    grid-area: campemail;
  }
  
  .CampHost_startenddate__3I2Oh {
    grid-area: startenddate;
  }
  
  .CampHost_yearround__3dGqS {
    grid-area: yearround;
  }
  
  /* .campdaysandhours {
    grid-area: campdaysandhours;
    width: 60%;
    max-width: 1100px;
  } */
  
  .CampHost_agegroup__2OJBU {
    grid-area: agegroup;
    align-self: start;
  }
  
  .CampHost_spotsavailable__370-H {
    grid-area: spotsavailable;
  }
  
  .CampHost_costpercamper__2wEBZ {
    grid-area: costpercamper;
  }
  
  .CampHost_campdetails__FbSt1 {
    grid-area: campdetails;
  }
  
  .CampHost_addcampbutton__6ljLt {
    grid-area: addcampbutton;
  }
  
  .CampHost_responsemessage__3cAMi {
    grid-area: responsemessage;
  }
  
  .CampHost_agegroupinput__2Un7d {
    width: 50px;
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdayinput__N-Flj {
    margin: 20px;
  }
  
  .CampHost_camphoursinput__1AKGI {
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdaysandhoursinput__R9Sdk {
    display: grid;
    grid-template-columns: 100px 80px 1fr 1fr;
  }
  
  .CampHost_nocamps__1p0Xb {
    text-align: center;
    padding: 50px;
  }
  
  .CampHost_registered-camper-container__wxdvU {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .CampHost_registered-camper-table__3IPdn {
    border: 3px solid white;
    width: 100%;
    font-size: 0.9em;
  }
  
  .CampHost_registered-camper-table__3IPdn tr,
  td {
    border: 3px solid white;
  }
  
  @media (max-width: 480px) {
    .CampHost_registered-camper-container__wxdvU {
      width: 90%;
    }
  }
  
  .CampHost_camp-registrations-card-image__2dISL {
    width: 200px;
    height: 150px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .CampHost_category__1Enqi {
      grid-area: category;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    .CampHost_registered-camper-container__wxdvU {
      width: 85%;
    }
  
    .CampHost_gray-container__J5qen {
      width: 100%;
      background-color: whitesmoke;
      margin-top: 16em;
    }
  }

  div.CampHost_react-datepicker-wrapper__yX21z{
    width:100%;
  }
.CampHost_hero__2nK6B {
    min-height: 600px;
    background-image: url(/static/media/showcaseone.19f36f17.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    margin: auto;
  }
  
  .CampHost_form-text__1BCKU {
    color: #ff794d;
    text-align: center;
    padding-top: 30px;
  }
  
  .CampHost_gray-container__1Sxda {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 6em;
  }
  
  .CampHost_edit-content__1JV5O {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 15em;
  }
  
  .CampHost_mycamps__kfSJ7 {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .CampHost_my-camp-card-container__UggNU {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .CampHost_my-camp-card-image__KCWbK {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .CampHost_my-camp-card-image__KCWbK:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .CampHost_my-camp-card-text__cfEh4 {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  .CampHost_form-text__1BCKU a {
    color: #ff794d;
  }
  
  .CampHost_form-text__1BCKU a:hover {
    text-decoration: none;
  }
  
  .CampHost_header__apEtM {
    position: fixed;
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    top: 80px;
    z-index: 100;
  
  }
  
  .CampHost_form-container__3GgmJ {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .CampHost_subtext__2kgNX {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    color: #ff794d;
  }
  
  .CampHost_subtext__2kgNX a {
    color: #ff794d;
    text-decoration: none;
  }
  
  .CampHost_add-camp-form__fmWtF {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'orgName orgName orgName . . .'
      'campTitle campTitle campTitle . . .'
      'category category category category . .'
      'address address address address address address'
      'city city city state zip .'
      'phone phone campemail campemail . .'
      'startenddate startenddate . yearround . .'
      'agegroup spotsavailable costpercamper . . .'
      'campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours campdaysandhours'
      'campdetails campdetails campdetails campdetails . .'
      '. . . . . addcampbutton'
      '. . . . responsemessage responsemessage';
    grid-gap: 10px;
  }

  
  
  .CampHost_orgName__2IYn2 {
    grid-area: orgName;
  }
  
  .CampHost_campTitle__1Rj4a {
    grid-area: campTitle;
  }
  
  .CampHost_category__1zl3G {
    grid-area: category;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
  .CampHost_imageupload__2v58G {
    grid-area: imageupload;
    align-self: end;
  }
  
  .CampHost_address__2tQ9k {
    grid-area: address;
  }
  
  .CampHost_city__2CsQj {
    grid-area: city;
  }
  
  .CampHost_state__2YCI9 {
    grid-area: state;
    align-self: end;
  }
  
  .CampHost_dropdown__12Nrl {
    width: 100%;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 16px;
    height: 45px;
  }
  
  .CampHost_zip__3c1Ms {
    grid-area: zip;
  }
  
  .CampHost_phone__38e2T {
    grid-area: phone;
  }
  
  .CampHost_campemail__3M8k7 {
    grid-area: campemail;
  }
  
  .CampHost_startenddate__24r-G {
    grid-area: startenddate;
  }
  
  .CampHost_yearround__3GP6p {
    grid-area: yearround;
  }
  
  /* .campdaysandhours {
    grid-area: campdaysandhours;
    width: 60%;
    max-width: 1100px;
  } */
  
  .CampHost_agegroup__1E6gs {
    grid-area: agegroup;
    align-self: start;
  }
  
  .CampHost_spotsavailable__gv0e5 {
    grid-area: spotsavailable;
  }
  
  .CampHost_costpercamper__31Bvt {
    grid-area: costpercamper;
  }
  
  .CampHost_campdetails__3W75N {
    grid-area: campdetails;
  }
  
  .CampHost_addcampbutton__XkSQL {
    grid-area: addcampbutton;
  }
  
  .CampHost_responsemessage__2fMEa {
    grid-area: responsemessage;
  }
  
  .CampHost_agegroupinput__36MzJ {
    width: 50px;
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdayinput__1EeQq {
    margin: 20px;
  }
  
  .CampHost_camphoursinput__3fSOW {
    padding: 2.5px 2.5px;
    border: 2px solid #b9bbb6;
    border-radius: 10px;
    background-color: #d4d4d4;
    box-shadow: #b9bbb6;
    font-size: 18px;
  }
  
  .CampHost_campdaysandhoursinput__JBYYv {
    display: grid;
    grid-template-columns: 100px 80px 1fr 1fr;
  }
  
  .CampHost_nocamps__1zHkz {
    text-align: center;
    padding: 50px;
  }
  
  .CampHost_registered-camper-container__1AtWd {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .CampHost_registered-camper-table__2W9aa {
    border: 3px solid white;
    width: 100%;
    font-size: 0.9em;
  }
  
  .CampHost_registered-camper-table__2W9aa tr,
  td {
    border: 3px solid white;
  }
  
  @media (max-width: 480px) {
    .CampHost_registered-camper-container__1AtWd {
      width: 90%;
    }
  }
  
  .CampHost_camp-registrations-card-image__2lWxA {
    width: 200px;
    height: 150px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .CampHost_category__1zl3G {
      grid-area: category;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    .CampHost_registered-camper-container__1AtWd {
      width: 85%;
    }
  
    .CampHost_gray-container__1Sxda {
      width: 100%;
      background-color: whitesmoke;
      margin-top: 16em;
    }
  }

  div.CampHost_react-datepicker-wrapper__2JGAC{
    width:100%;
  }
