.btn--default {
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    color: white;
    min-width: 180px;
    padding: 2.5px;
    text-transform: uppercase;
    border: none;
    transition: transform 0.3s ease;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
  }
  
  .btn--large {
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      rgba(254, 240, 55, 1) 0%,
      rgba(234, 68, 58, 1) 100%
    );
    color: white;
    min-width: 220px;
    padding: 2.5px;
    text-transform: uppercase;
    border: none;
    transition: transform 0.3s ease;
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
  }
  
  .btn--default:hover,
  .btn--large:hover {
    transform: translateY(-3px);
    color: white;
  }
  
  .icon {
    width: 20px;
  }
  