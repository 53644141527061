@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

@font-face {
  font-family: 'ChaletComprime';
  src: url(./fonts/chaletcomprime.otf), format('otf');
  /* font-style: 'CologneSixty'; */
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
a {
  font-family: 'Oswald', sans-serif;
}

p {
  font-family: 'Montserrat', sans-serif;
}

.overhead {
  background-color: rgb(236, 78, 43);
  border-radius: 0px 0px 75px 25px;
  color: white;
  opacity: 0.89;
  margin-left: 5% !important;
  width: 32% !important;
  height: 40% !important;
  padding: 30px !important;
  line-height: 1.1 !important;
}

.lower-right {
  margin-top: 30px !important;
  margin-right: 1em !important;
  color: white;
  width: 25% !important;
  top: 10em;
  line-height: 1.1 !important;
}

.input {
  width: 100%;
  padding: 2.5px 2.5px;
  border: 2px solid #b9bbb6;
  border-radius: 10px;
  background-color: #d4d4d4;
  box-shadow: #b9bbb6;
  font-size: 24px;
}

div.container input{
  margin-bottom:20px;
}

.header-text {
  color: #ff794d;
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
}

#dropdown-basic-button {
  background-color: #ff794d;
  border: 1px solid #b9bbb6;
}
