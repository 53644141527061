.hero {
    min-height: 400px;
    background-image: url('./images/campsearchbanner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1100px;
    height: 400px;
    margin: auto;
  }

  .category-list{
    list-style-type:"none";
    width:950;
    margin-top:15;
  }
  .category-list li{
    float:left;
  }
  @media (max-width: 480px) {
    li{
      margin-right:0 !important;
    }
    li:nth-child(even){
      margin-left:.5rem
    } 
  }


  .header {
    background-color: white;
    left: calc(-50vw + 40%);
    right: calc(-50vw + 40%);
    margin-left: auto;
    margin-right: auto;
    color: #ff794d;
    margin-top:10px;
  }
  
  .hero-text {
    width: 300px;
    height: 150px;
    text-align: center;
    font-size: 35px;
    line-height: 5%;
    padding-top: 20px;
    color: white;
  }
  
  .hero-text-emphasis {
    font-size: 70px;
    line-height: normal;
  }
  
  .search {
    width: 70%;
    max-width: 1100px;
    margin: auto;
    padding-top: 70px;
    display: flex;
  }
  
  .camp-search-form {
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas: 'grid-item-one grid-item-two grid-item-three';
  }
  
  .grid-item-one {
    grid-area: grid-item-one;
  }
  
  .grid-item-two {
    grid-area: grid-item-two;
  }
  
  .grid-item-three {
    grid-area: grid-item-three;
  }
  
  .header-text {
    text-align: center;
    padding-top: 50px;
    color: #ff794d;
  }
  
  b {
    text-align: center;
    color: #ff794d;
  }
  
  .gray-container {
    width: 100%;
    background-color: whitesmoke;
    margin-top: 10px;
  }
  
  .camp-category-card-container {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .camp-category-card-image {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .camp-category-card-image:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .camp-category-card-text {
    padding-top: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #ff794d;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
  }
  
  /* CampList and CampCard */
  
  .camps {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
  }
  
  .camp-card-container {
    width: 750px;
    height: 325px;
    margin: 15px;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'image campinfo'
      '. button';
    padding: 20px;
  }
  
  .camp-card-image {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    margin: 15px;
    grid-area: image;
  }
  
  .camp-card-info {
    width: 450px;
    height: 250px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr 1fr 2fr;
    grid-template-areas:
      'name campname'
      'location camplocation'
      'dates campdates'
      'summary campsummary';
    grid-area: campinfo;
    margin: 15px;
  }
  
  .camp-card-button {
    grid-area: button;
    margin: auto;
    justify-self: center;
  }
  
  .name {
    grid-area: name;
  }
  
  .campname {
    grid-area: campname;
  }
  
  .location {
    grid-area: location;
  }
  
  .camplocation {
    grid-area: camplocation;
  }
  
  .dates {
    grid-area: dates;
  }
  
  .campdates {
    grid-area: campdates;
  }
  
  .summary {
    grid-area: summary;
  }
  
  .campsummary {
    grid-area: campsummary;
  }
  
  .camp-card-header-text {
    font-family: 'Oswald', sans-serif;
  }
  
  /* CampProfile */
  
  .camp-profile-primary-info-container {
    width: 70%;
    max-width: 1000px;
    margin: auto;
    padding-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'carousel primaryinfo';
    padding-bottom: 35px;
  }
  
  .camp-profile-carousel {
    grid-area: carousel;
  }
  
  .camp-profile-primary-info {
    grid-area: primaryinfo;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
      'campname campnameinfo'
      'camphost camphostinfo'
      'address addressinfo'
      'dates datesinfo'
      'age ageinfo'
      /*'categories categoriesinfo'*/
      'contact contactinfo'
      'cost costinfo'
      'spots spotsinfo'
      'button button';
    padding: 20px;
    grid-gap: 5px;
  }

  .org-profile-primary-info{
    grid-area: primaryinfo;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
      'orgname orgnameinfo'
      'address addressinfo'
      'contact contactinfo'
      'button button';
    padding: 20px;
    grid-gap: 5px;
  }
  
  .camp-profile-header-text {
    font-family: 'Oswald', sans-serif;
  }
  
  .camp-profile-additional-info {
    padding-top: 35px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    padding-bottom: 35px;
  }
  
  .campname {
    grid-area: campname;
  }
  
  .campnameinfo {
    grid-area: campnameinfo;
  }
  
  .camphost {
    grid-area: camphost;
  }
  
  .camphostinfo {
    grid-area: camphostinfo;
  }
  
  .website {
    grid-area: website;
  }
  
  .websiteinfo {
    grid-area: websiteinfo;
  }
  
  .address {
    grid-area: address;
  }
  
  .addressinfo {
    grid-area: addressinfo;
  }
  
  .dates {
    grid-area: dates;
  }
  
  .datesinfo {
    grid-area: datesinfo;
  }
  
  .time {
    grid-area: time;
  }
  
  .timeinfo {
    grid-area: timeinfo;
  }
  
  .age {
    grid-area: age;
  }
  
  .ageinfo {
    grid-area: ageinfo;
  }
  
  .button {
    grid-area: button;
  }

  .hero-button {
    border-radius: 10px;
    background-color: white;
    color: #36454f;
    text-transform: uppercase;
    padding: 2px 40px;
    font-size: 20px;
    margin-top: 20px;
  }

