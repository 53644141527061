.footer-container {
    padding-top: 30px;
    display: grid;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    font-size: 24px;
    background: white;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'grid-item-one grid-item-two grid-item-three'
      'grid-item-four grid-item-five grid-item-six';
  }
  
  .footer-container a:hover {
    text-decoration: none;
    color: black;
  }
  
  .grid-item-two {
    display: flex;
    justify-self: center;
    align-self: end;
    grid-area: grid-item-two;
  }
  
  .grid-item-four {
    grid-area: grid-item-four;
    align-self: center;
  }
  
  .grid-item-five {
    grid-area: grid-item-five;
    justify-self: center;
  }
  
  .grid-item-six {
    grid-area: grid-item-six;
    align-self: center;
  }
  
  .logo {
    width: 260px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  
  .navigation-link {
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: #36454f;
  }
  
  .menu {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .social-icon {
    height: 60px;
    width: 60px;
    padding: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .social-icon:hover {
    transform: translateY(-6px);
  }
  
  @media only screen and (max-width: 1000px) {
    .footer-container {
      display: grid;
      width: 90%;
      margin: auto;
      font-size: 24px;
      background: white;
      grid-template-columns: 1fr;
      grid-template-rows: 85px 85px 25px 25px;
      grid-template-areas:
        'grid-item-two'
        'grid-item-five'
        'grid-item-four'
        'grid-item-six';
      align-items: center;
    }
    .grid-item-four {
      display: flex;
      justify-content: center;
    }
    .grid-item-six {
      display: flex;
      justify-content: center;
    }
    .menu {
      padding: 0;
      justify-content: center;
    }
    .menu a {
      padding: 5px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .footer-container {
      grid-template-areas:
        'grid-item-two'
        'grid-item-five'
        'grid-item-six';
    }
    .grid-item-four {
      display: none;
    }
  }
  